<template>
  <div class="operation-data-statistics" ref="operationDataStatistics">
    <div class="function-buttons" ref="functionButtons" v-show="false">
      <el-button type="primary" size="small"></el-button>
    </div>
    <div class="form-area" ref="form">
      <analysisForm @getFormVal="onSubmit">
        <el-button
          @click="getXLS"
          type="primary"
          size="small"
          :loading="downLoadStatus"
          :disabled="tableData.length === 0"
        >导出</el-button>
      </analysisForm>
    </div>
    <el-table id="outputTable" :data="tableData" stripe :height="tableHeight" width="100%">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="companyName" label="所属车队" width="150" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="cph" label="车牌号" width="120" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column
        prop="drivingMileage"
        label="行驶里程（km）"
        width="140"
        :show-overflow-tooltip="true"
        :formatter="formatMileage"
      ></el-table-column>
      <el-table-column
        prop="operationMileage"
        label="载客里程（km）"
        width="140"
        :formatter="formatMileage"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="emptyDrivingMileage"
        label="空驶里程（km）"
        width="140"
        :formatter="formatMileage"
        :show-overflow-tooltip="true"
      ></el-table-column>

      <el-table-column
        prop="mileageUtilization"
        :formatter="formatRate"
        label="里程利用率"
        width="120"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="carriedPassengerTime"
        width="180"
        :show-overflow-tooltip="true"
        :formatter="formateTime"
        label="载客时长"
      ></el-table-column>
      <el-table-column
        prop="emptyDrivingTime"
        width="220"
        :show-overflow-tooltip="true"
        :formatter="formateTime"
        label="空驶时长"
      ></el-table-column>
      <el-table-column prop="carriedPassengerCount" label="载客次数"></el-table-column>
      <el-table-column
        prop="revenueAmount"
        label="营收金额（元）"
        width="120"
        :formatter="formatMoney"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="averageOperationMileage"
        label="平均载客里程（km）/次"
        width="200"
        :formatter="formatIndex"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="avgCarriedPassengerCount"
        width="220"
        label="平均载客时长 /次"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="avgRevenueAmount"
        width="190"
        label="平均营收金额（元）/次"
        :formatter="formatIndex"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="avgDrivingRevenueAmount"
        width="220"
        label="平均行驶里程收入（元/km）"
        :formatter="formatIndex"
        :show-overflow-tooltip="true"
      ></el-table-column>
    </el-table>
    <div ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import analysisForm from '@/components/analysisForm/analysisForm.vue'
import { YHTools } from '@/assets/js/Tools.js'
import { queryByConditionList } from '@/api/lib/taxi-api.js'
import { addExcelExport } from "@/api/lib/refreshAlarm.js";
import { getCurent } from '@/common/utils/index'

export default {
  name: 'operationDataStatistics',
  components: {
    analysisForm
  },
  data () {
    return {
      tableHeight: 0,
      tableData: [],
      form: {
        currentPage: 1,
        pageSize: 10
      },
      exportForm: {},
      downLoadStatus: false,
      total: 0,
    }
  },
  methods: {
    /** 导出 */
    getXLS () {
      // getXLS('#outputTable', '运营数据统计')
      this.downLoadStatus = true;
      delete this.exportForm.currentPage;
      delete this.exportForm.pageSize;
      delete this.exportForm.date;
      let data = {
        baseUrl: "taxi",
        fileName: `运营数据统计-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: "/operationalStatistics/export",
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    // 计算表格高度
    computeHeight () {
      let wholeHeight = this.$refs.operationDataStatistics.clientHeight
      let buttonsHeight = this.$refs.functionButtons.clientHeight
      let formHeight = this.$refs.form.clientHeight
      let paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight = wholeHeight - 16 - buttonsHeight - 16 - formHeight - paginationHeight - 10
    },
    // 提交
    onSubmit (value) {
      if (value.companyId) {
        this.form.companyIdList = [value.companyId]
      } else {
        this.form.companyIdList = []
      }
      this.form.vehicleNoList = value.vehicleNos;
      this.form.endTime = value.endTime;
      this.form.beginTime = value.beginTime;
      this.form.currentPage = 1;
      if(value.endTime == value.beginTime){
        this.$message.warning('不能选择同一天查询！')
        return
      }
      this.getDataList()
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page
      this.getDataList()
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size
      this.getDataList()
    },
    // 获取列表
    getDataList () {
      this.exportForm = { ...this.form }
      queryByConditionList(this.form).then(res => {
        if(res.code===1100){
          this.$message.warning(res.msg)
        }
        if (res.total > 0) {
          this.tableData = res.list;
          this.total = res.total;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      })
    },
    /** 数据% */
    formatRate (row, column, cellValue) {
      return cellValue == null || cellValue === '' ? '-' : `${this.formatNumber(cellValue, 100)}%`
    },
    /** */
    formatIndex (row, column, cellValue) {
      return this.formatNumber(cellValue)
    },
    /** 时长 */
    formateTime (cellrow, column, cellValue) {
      return cellValue == null || cellValue === '' ? '-' : YHTools.FormatTimeBySeconds(cellValue, true)
    },
    /** km */
    formatMileage (row, column, cellValue) {
      return cellValue == null || cellValue === '' ? '-' : `${this.formatNumber(cellValue)}KM`
    },
    /** Money */
    formatMoney (cellrow, column, cellValue) {
      return cellValue == null || cellValue === '' ? '-' : `${this.formatNumber(cellValue)}元`
    },
    /** 整数||小数 */
    formatNumber (cellValue, number) {
      number = number || 1
      return parseInt(cellValue * number) < parseFloat(cellValue * number) ? (cellValue * number).toFixed(2) : cellValue * number
    },
  },
  created () {

  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.operation-data-statistics {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
}

.add {
  height: 100%;
  padding: 2vh 3vh;
}

.theme-project-taxi {
  .operation-data-statistics {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
