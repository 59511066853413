var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "operationDataStatistics",
      staticClass: "operation-data-statistics"
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false"
            }
          ],
          ref: "functionButtons",
          staticClass: "function-buttons"
        },
        [_c("el-button", { attrs: { type: "primary", size: "small" } })],
        1
      ),
      _c(
        "div",
        { ref: "form", staticClass: "form-area" },
        [
          _c(
            "analysisForm",
            { on: { getFormVal: _vm.onSubmit } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    loading: _vm.downLoadStatus,
                    disabled: _vm.tableData.length === 0
                  },
                  on: { click: _vm.getXLS }
                },
                [_vm._v("导出")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: {
            id: "outputTable",
            data: _vm.tableData,
            stripe: "",
            height: _vm.tableHeight,
            width: "100%"
          }
        },
        [
          _c("el-table-column", { attrs: { type: "index", label: "序号" } }),
          _c("el-table-column", {
            attrs: {
              prop: "companyName",
              label: "所属车队",
              width: "150",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "cph",
              label: "车牌号",
              width: "120",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "drivingMileage",
              label: "行驶里程（km）",
              width: "140",
              "show-overflow-tooltip": true,
              formatter: _vm.formatMileage
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "operationMileage",
              label: "载客里程（km）",
              width: "140",
              formatter: _vm.formatMileage,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "emptyDrivingMileage",
              label: "空驶里程（km）",
              width: "140",
              formatter: _vm.formatMileage,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "mileageUtilization",
              formatter: _vm.formatRate,
              label: "里程利用率",
              width: "120",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "carriedPassengerTime",
              width: "180",
              "show-overflow-tooltip": true,
              formatter: _vm.formateTime,
              label: "载客时长"
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "emptyDrivingTime",
              width: "220",
              "show-overflow-tooltip": true,
              formatter: _vm.formateTime,
              label: "空驶时长"
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "carriedPassengerCount", label: "载客次数" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "revenueAmount",
              label: "营收金额（元）",
              width: "120",
              formatter: _vm.formatMoney,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "averageOperationMileage",
              label: "平均载客里程（km）/次",
              width: "200",
              formatter: _vm.formatIndex,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "avgCarriedPassengerCount",
              width: "220",
              label: "平均载客时长 /次",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "avgRevenueAmount",
              width: "190",
              label: "平均营收金额（元）/次",
              formatter: _vm.formatIndex,
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "avgDrivingRevenueAmount",
              width: "220",
              label: "平均行驶里程收入（元/km）",
              formatter: _vm.formatIndex,
              "show-overflow-tooltip": true
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { ref: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.form.pageSize,
              "current-page": _vm.form.currentPage
            },
            on: {
              "size-change": _vm.onSizeChange,
              "current-change": _vm.onCurrentChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }